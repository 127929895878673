<template>
  <div class="xxjj">
    <div class="page-herader">
      <span>{{content.name}}</span>
    </div>
    <div class="lmy-center">
      <!-- <div v-if="content.contentData.primersTitle" style="padding: 0 0 0.52083vw 3.64583vw;font-size: 1.14583vw;color: #333333;">{{ content.contentData.primersTitle }}</div>
      <div style="font-size: 1.77083vw;text-align: center;padding: 0 0 0.52083vw 3.64583vw">{{ content.contentData.title }}</div>
      <div v-if="content.contentData.subTitle" style="padding: 1.04167vw 1.04167vw 0.52083vw 1.04167vw;font-size: 1.14583vw;color: #333333;text-align: right;">{{ content.contentData.subTitle }}</div>
      <div class="content_source" style="padding: 0.83333vw 0 0 3.64583vw;font-size: 0.83333vw;color: #6c6a6a;">
        <el-divider direction="vertical"></el-divider>
        <span v-if="content.contentData.mapProperties.wenzi">文字：{{ content.contentData.mapProperties.wenzi }}</span>
        <span v-if="content.contentData.mapProperties.sheying">摄影：{{ content.contentData.mapProperties.sheying }}</span>
        <span v-if="content.contentData.origin">来源：{{ content.contentData.origin }}</span>
        <span v-if="content.contentData.publishTime">{{$moment(content.contentData.publishTime).format('YYYY-MM-DD')}}</span>
      </div> -->
      <div class="content 6" v-html="content.contentData.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    // this.getStory();
  },
  methods: {
    async getStory() {
      const res = await this.API.basic.getStory("WS6359eda5e4b03007b5f024ad");
      this.content = res.data.content;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep a{
      color: #002bfd !important;
    }
img{
  max-width: 100%;
}
.content_source{
    span{
      padding-right: 16px;
    }
  }
.lmy-center {
  margin-top: 18px;
  margin-bottom: 36px;
  p {
    font-size: 24px;
    text-align: center;
  }
  img {
    max-width: 100%;
  }
  .content {
    padding: 0 100px;
    font-size: 16px;
    #zoom {
      margin-top: 50px;
    }
  }
}
</style>
