<template>
  <div class="xxjj">
    <div class="page-herader">
      <span>继续教育</span>
    </div>
    <div class="lmy-center">
      <div class="content 7" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: ""
    };
  },
  mounted() {
    this.getStory();
  },
  methods: {
    async getStory() {
      const res = await this.API.basic.getStory('WS6359eda1e4b03007b5f024ac');
      this.content = res.data.content
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep a{
      color: #002bfd !important;
    }
img{
  max-width: 100%;
}
.lmy-center {
  margin-top: 18px;
  margin-bottom: 36px;
  p {
    font-size: 24px;
    text-align: center;
  }
  img {
    max-width: 100%;
  }
  .content {
    padding: 0 100px;
    font-size: 16px;
  }
}
</style>